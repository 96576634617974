import React, { useEffect, useState, useRef } from "react";
import { withPrefix } from "gatsby";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedProductKeys, selectCheckoutState, setShowProductDetailsModal } from "../sagas/checkout/checkoutSlice";
import { createTask, getPrintfulTask } from "../services/apiCalls";
import { updateCarousel } from "../sagas/carousels/carouselsSlice";

function waitFiveSeconds() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, 5000);
  });
}

const getPrintfulData = async(task_key) => {
  let completed = false;
  let count = 0;
  let printfulRes;

  while (!completed && count < 10) {
    await waitFiveSeconds()
    printfulRes = await getPrintfulTask(task_key, 'no');
    if (printfulRes.result?.status === "completed") {
      completed = true;
    } else {
      count++;
    }
  }

  return printfulRes?.result?.mockups;
}

export default function MyCreationsViewProduct({
  product,
  productKey,
  productLabel,
  dataId,
  carousel,
  updateProductsObject,
}) {
  const dispatch = useDispatch();
  const { SelectedProductKeys } = useSelector(selectCheckoutState);

  const getImage = () => {
    return product?.[0]?.value?.[0] ? `${product[0].value[0]}?version=200` : withPrefix("assets/img/green-little-balls.gif");
  }

  const [isSelected, setIsSelected] = useState(false);
  const [orderNo, setOrderNo] = useState(0);
  useEffect(() => {
    if (SelectedProductKeys.includes(dataId)) {
      setIsSelected(true);
      setOrderNo(SelectedProductKeys.findIndex((key) => key === dataId) + 1);
    } else {
      setIsSelected(false);
      setOrderNo(0);
    }
  }, [SelectedProductKeys])

  const selectProduct = (e) => {
    e.stopPropagation();
    dispatch({
      type: setSelectedProductKeys.type,
      payload: {
        SelectedProductKeys: [...SelectedProductKeys, dataId],//Note: we cannot use ProductKey cause that doesn't support multiple carousels
      },
    });
  }

  const handleBoxClick = () => {
    if (generatingSku) return;

    if (!product) return;

    if (isSelected) {
      dispatch({
        type: setSelectedProductKeys.type,
        payload: {
          SelectedProductKeys: SelectedProductKeys.filter((key) => key !== dataId),//Note: we cannot use ProductKey cause that doesn't support multiple carousels
        },
      });      
    } else {
      dispatch({
        type: setShowProductDetailsModal.type,
        payload: {
          ProductDetailsModalProps: {
            open: true,
            product,
            productKey,
            productLabel,
            dataId,
          }
        },
      });   
    }
  }

  const onClickExpand = (e) => {
    e.stopPropagation();
    dispatch({
      type: setShowProductDetailsModal.type,
      payload: {
        ProductDetailsModalProps: {
          open: true,
          product,
          productKey,
          productLabel,
          dataId,
        }
      },
    });
  }

  const [generatingSku, setGeneratingSku] = useState(false);
  const handleRefreshClick = async (e) => {
    e.stopPropagation();

    setGeneratingSku(true);

    const { carousel_uID, thumbnail, upscaled, logo, carousel_created_Time } = carousel;

    try {
      const taskRes = await createTask({
        image: upscaled,
        logo: logo ?? undefined,
        name: productKey,
      });

      if (taskRes.status == "success") {
        const { result } = taskRes.data.que;
        const mockups = await getPrintfulData(result.task_key);

        if (mockups?.length) {
          const images = [];
          const placement = [];
          
          mockups.forEach((mockup) => {
            images.push(mockup.mockup_url);
            placement.push(mockup.placement);
          });

          updateProductsObject([{
            placement, value: images
          }]);

          let items = JSON.parse(localStorage.getItem("allItems"));
          items = items.filter((item) => item.key !== dataId);
          items = [...items, { carousel_uID, key: dataId, thumbnail, upscaled, logo, value: { images, placement } }];
          localStorage.setItem("allItems", JSON.stringify(items));

          dispatch({
            type: updateCarousel.type,
            payload: {
              carousel_uID,
              carousel_created_time: carousel_created_Time,
              carousel: [{ placement, value: images }],
              thumbnail,
              skuName: productKey,
              upscaled,
            }
          });

        }
      }      
    } catch (error) {
      console.log(error);
    } finally {
      setGeneratingSku(false);
    }
  }

  const boxRef = useRef(null);
  const width = boxRef?.current?.offsetWidth ?? 0;
  const [height, setHeight] = useState();
  useEffect(() => {
    if (!product && width) {
      setHeight(width);
    }
  }, [product, width])

  return (
    <div className="col-md-2">
      <div
        className={`box ${isSelected ? 'selected' : ''} ${(!product && !generatingSku) ? 'refresh' : ''}`}
        data-id={dataId}
        onClick={handleBoxClick}
        ref={boxRef} 
        style={{ height: height ?? 'unset' }}
      >
        <div className={productKey + " figure"}>
          {product ? (
            <ImageItem src={getImage()} alt={productLabel} />
          ) : generatingSku ? (
            <img
              className="merchImages"
              id="merchImages"
              src={withPrefix("assets/img/green-little-balls.gif")}
              alt={productLabel}
            />
          ) : (
            <div className="refresh-btn-container" style={{ display: 'inline-block' }}>
              <div className="txt-max">
                <div className="icon-info">
                  <img src={withPrefix("assets/img/icon-info-lrg.png")} alt="icon-info-lrg" />
                </div>
                <p style={{ margin: '1rem' }}>{productLabel}</p>
                <button className="btn btn-blue btn-refresh" onClick={handleRefreshClick}>Generate</button>
              </div>
            </div>
          )}
        </div>

        {product && (
          <>
            <div className="aside1">
              <a className="tool-tip">
                {productLabel}
              </a>
            </div>
            <div className="aside">
              {isSelected ? (
                <>
                  <div className="number">{orderNo}</div> 
                  <a className="expand-btn" onClick={onClickExpand}>Expand</a>
                </>
              ) : (
                <a className="select-btn" onClick={selectProduct}>Select</a>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const ImageItem = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div style={{ position: 'relative', paddingTop: '100%' }}>
      <img 
        className="merchImages"
        id="merchImages"
        src={src}
        alt={alt}
        style={{ 
          opacity: imageLoaded ? 1: 0, 
          position: 'absolute',
          top: 0,
          left: 0,
        }} 
        onLoad={() => setImageLoaded(true)} 
      />
      {!imageLoaded && (
        <img 
          src={withPrefix("assets/img/green-little-balls.gif")} 
          className="merchImages"
          id="merchImages"
          alt={alt}
          style={{ 
            opacity: imageLoaded ? 0: 1, 
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      )}
    </div>
  )
}
