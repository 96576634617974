import React, { useState, useEffect } from "react";
import MyCreationsViewProduct from "./my-creations-view-product";
import { products_array, tooltips_array } from "../services/mock";

export default function MyCreationsViewCarousel({ carousel }) {
  const [productsObject, setProductsObject] = useState();
  useEffect(() => {
    const products = JSON.parse(carousel.carousel);
    setProductsObject(products);
  }, [carousel])

  return (
    <div className={`item ${carousel.carousel_uID}`}>
      <div className="container-inn">
        <div className="row" id="product-carousel-slot">
          {!!productsObject && products_array.map((key, i) => (
            <MyCreationsViewProduct
              key={i}
              productKey={key}
              product={productsObject[key]}
              productLabel={tooltips_array[i]}
              dataId={`${carousel.carousel_uID}_${key}`}
              carousel={carousel}
              updateProductsObject={(product) => {
                setProductsObject((productsObject) => {
                  return {
                    ...productsObject,
                    [key]: product,
                  };
                })
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
