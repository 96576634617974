import React, { useState, useEffect, useRef } from 'react';
import { withPrefix } from "gatsby";
import { navigate } from "gatsby";
import { isLoggedIn } from "../services/auth";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import MyCreationsViewCarousel from "../components/my-creations-view-carousel";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Thumbs, FreeMode } from 'swiper/modules';
import { useApplicationContext } from "../../provider";
import {products_array} from '../services/mock';
import '../components/css/my-creations-view.scss';
import GoNextModal from '../components/MyCreationsView/GoNextModal';
import AddNewStoreModal from '../components/MyCreationsView/AddNewStoreModal';
import SelectStoreModal from '../components/MyCreationsView/SelectStoreModal';
import StoreCollectionsModal from '../components/MyCreationsView/StoreCollectionsModal';
import ReviewCreationsModal from '../components/MyCreationsView/ReviewCreationsModal';
import ProductDetailsModal from '../components/MyCreationsView/ProductDetailsModal';
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getUserStores, selectStoresState } from "../sagas/stores/storesSlice";
import Swal from 'sweetalert2';
import { selectCheckoutState, setSelectedProductKeys } from "../sagas/checkout/checkoutSlice";
import Backdrop from '@mui/material/Backdrop';
import { postImage, selectImageState } from "../sagas/image/imageSlice";
import CropImageModal from "../components/CropLogoModal";
import { generateUniqueCarouselUID } from "../services/utils";
import SelectMerchCarousel from "../components/select-merch-carousel";
import { getUserFromAccessToken } from "../services/auth";

export default function MyCreationsView() {
  const currentUser = getUserFromAccessToken();
  const dispatch = useDispatch();
  const { UserStores } = useSelector(selectStoresState);
  const { SelectedProductKeys } = useSelector(selectCheckoutState);
  const matchesMobile = useMediaQuery("(max-width:425px)");
  const { checkoutState, setCheckoutState } = useApplicationContext();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [carousels, setCarousels] = useState([]);
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const [showNextBtn, setShowNextBtn] = useState(false);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
      return;
    }

    localStorage.removeItem("allItems");

    setCheckoutState({ ...checkoutState, newJourney: true, flow: 'my-creations' });

    const selectedCarouselUuids = typeof window !== "undefined" && JSON.parse(localStorage.getItem('selectedCarouselUuids') || '[]');
    const myCreations = typeof window !== "undefined" ? JSON.parse(localStorage.getItem('myCreations') || '[]') : [];
    const myCarouselArray = myCreations
      .filter((mc) => selectedCarouselUuids.includes(mc.records[0].carousel_uID))
      .map((mc) => mc.records[0]);

    if (myCarouselArray.length) {
      setCarousels(myCarouselArray);
      setCurrentCarouselIndex(0);
      setShowNextBtn(true);
      setIsFixed(true);

      const allItems = [];
      for (let k = 0; k < myCarouselArray.length; k++) {
        let oneCarouselData = myCarouselArray[k].carousel;
        let thumbnail = myCarouselArray[k].thumbnail;
        let upscaled = myCarouselArray[k].upscaled;
        let carousel_uID = myCarouselArray[k].carousel_uID;
        let logo = myCarouselArray[k].logo;
        if(oneCarouselData  && oneCarouselData.length > 0) {
          let myArrays = JSON.parse(oneCarouselData);
          for(let idx = 0 ; idx < products_array.length ; idx ++) {
            if (!!myArrays[products_array[idx]]) {
              allItems.push({
                key: carousel_uID + "_" + products_array[idx], 
                value: {
                  images: myArrays[products_array[idx]]?.[0].value, 
                  lacement: myArrays[products_array[idx]]?.[0].placement,
                }, 
                thumbnail, 
                upscaled, 
                logo, 
                carousel_uID,
              });
            }
          }
        }
      }
      localStorage.setItem("allItems", JSON.stringify(allItems));
    }
  }, [])


  const onAddToCart = (e) => {
    //Below code will be removed soon when we completely finish refactoring stuff
    localStorage.setItem("selectedArray", JSON.stringify(SelectedProductKeys));

    e.preventDefault();
    navigate("/cart-listing");
  }

  //Publish storefront: start
  //fetch user's storefronts
  useEffect(() => {
    dispatch(getUserStores());
  }, [])

  const [openGoNextModal, setOpenGoNextModal] = useState(false);
  const handleGoNextModal = () => {
    if (SelectedProductKeys.length > 0) {
      setOpenGoNextModal((flag) => !flag);
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select products to continue!',
      })
    }
  }
  const handleOpenNewStoreModal = () => {
    setOpenGoNextModal(false);
    // setOpenNewStoreModal(true);
    setOpenSelectStoreModal(true);
  }

  const [openNewStoreModal, setOpenNewStoreModal] = useState(false);
  const handleConfirmNewStoreModal = () => {
    setOpenNewStoreModal(false);
    setOpenSelectStoreModal(true);
  }

  const [openSelectStoreModal, setOpenSelectStoreModal] = useState(false);
  const handleConfirmSelectStoreModal = () => {
    setOpenSelectStoreModal(false);
    setOpenStoreCollectionsModal(true);
  }
  const handleAddStoreSelectStoreModal = () => {
    setOpenSelectStoreModal(false);
    setOpenNewStoreModal(true);
  }

  const [openStoreCollectionsModal, setOpenStoreCollectionsModal] = useState(false);
  const handleConfirmStoreCollectionsModal = () => {
    setOpenStoreCollectionsModal(false);
    setOpenReviewModal(true);
  }

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const handleConfirmReviewStoreModal = () => {
    setOpenReviewModal(false);
    navigate("/publishing-store");
  }
  const handleBackReviewStoreModal = () => {
    setOpenReviewModal(false);
    setOpenSelectStoreModal(true);
  }
  //Publish storefront: end

  const selectAllItems = () => {
    setOpenSettingsList(false);
    dispatch({
      type: setSelectedProductKeys.type,
      payload: {
        SelectedProductKeys: products_array.map((key) => `${carousels[currentCarouselIndex].carousel_uID}_${key}`),
      },
    });
  }

  const unSelectAllItems = () => {
    setOpenSettingsList(false);
    dispatch({
      type: setSelectedProductKeys.type,
      payload: {
        SelectedProductKeys: [],
      },
    });
  }

  const [openSettingsList, setOpenSettingsList] = React.useState(false);
  const handleCloseSettingsList = () => {
    setOpenSettingsList(false);
  };
  const handleOpenSettingsList = () => {
    setOpenSettingsList(true);
  };

  //sticky footer: start
  const [isFixed, setIsFixed] = useState(false);

  const offset = () => {
    const carouselHeight = document.querySelector(".my-creations-view").clientHeight;
    const windowHeight = window.innerHeight;
    return carouselHeight - windowHeight + 450;
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsFixed(scrollTop < offset());
  };

  useEffect(() => {
    if (carousels.length) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };      
    }
  }, [carousels]);  
  //sticky footer: end

  //start: Add Logo
  const [swiper, setSwiper] = useState(null);
  const logoInputRef = useRef(null);

  const handleAddLogo = () => {
    logoInputRef?.current?.click();
  }

  const runPipeline = (e) => {
    if (!e.target.files || e.target.files.length == 0) {
      return;
    }
    const file = e.target.files[0];

    if (!file) return;
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = async function () {
      setCropperSrc(reader.result);
      setModalOpen(true);
      setOpenSettingsList(false);
      e.target.value = null;
    };
  };

  const [slideNextFlag, setSlideNextFlag] = useState(false);
  const addLogo = (logoUrl) => {
    const new_carousels = carousels;

    const logoCarousel = {
      isNewLogoCarousel: true,
      logo: logoUrl,
      thumbnail: carousels[currentCarouselIndex].thumbnail,
      upscaled: carousels[currentCarouselIndex].upscaled,
      carousel_uID: generateUniqueCarouselUID(),
    };

    new_carousels.splice(currentCarouselIndex + 1, 0, logoCarousel);
    setCarousels(new_carousels);

    setOpenSettingsList(false);
    setSlideNextFlag(true);
    swiper?.update();
    thumbsSwiper?.update();
  }

  useEffect(() => {
    if (slideNextFlag && !!swiper) {
      swiper?.slideNext();
      setSlideNextFlag(false);
    }
  }, [slideNextFlag, swiper])

  const { isPostingImageSuccess, PostImageResponse } = useSelector(selectImageState);
  const [showAnimationflag, setShowAnimationflag] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [cropperSrc, setCropperSrc] = React.useState("");

  const handleCropModalClose = () => {
    setModalOpen(false);
    setShowAnimationflag(false);
    setCropperSrc('');
  }

  const [uploadingLogo, setUploadingLogo] = useState(false);
  const uploadCroppedImg = (base64data) => {
    setModalOpen(false);
    var real_str = base64data.slice(22);
    setShowAnimationflag(true);

    dispatch({
      type: postImage.type,
      payload: { base64Url: real_str },
    });

    setUploadingLogo(true);
  };
  useEffect(() => {
    if (isPostingImageSuccess && uploadingLogo) {
      handleCropModalClose();
      addLogo(PostImageResponse.url);
      setUploadingLogo(false);
    }
  }, [isPostingImageSuccess, PostImageResponse, uploadingLogo])
  //end: Add Logo

  return (
    <div className="container-main" id="page">
      <Header></Header>
      <main className="content-main my-creations-view-page">
        {showAnimationflag && (
          <div className="new_gif" style={{ display: "flex" }}>
            <img
              src={withPrefix("assets/img/green-little-balls.gif")}
              className="img-fluid mw_50"
              alt="loading"
            />
          </div>
        )}

        <div className="breadcrumb">
          <ul>
            <li><a href="#" onClick={(e) => { e.preventDefault();navigate("/my-creations"); }}>My Creations</a></li>
            <li><a href="#" onClick={(e) => e.preventDefault()}>Choose Merch</a></li>
          </ul>
        </div>

        {carousels.length ? (
          <>
            <div className="select-feature-list">
              <div className="container">
                <div className="my-creations select-merch">
                  <div className="row-container">
                    <div className="container-inn" id="page-header-1">
                      <div className="heading-top">
                        <h2>My Creations</h2>

                        <div className="results-wrapper">
                          <div className="results" style={{ lineHeight: '28px' }}>
                            <a href="#">{carousels[currentCarouselIndex].carousel_uID ?? ''}</a>
                          </div>
                          {showNextBtn && (
                            <div className="settings-box">
                              <div style={{ width: '56px', height: '56px' }}></div>
                              <div className="outlined-gear" style={{ zIndex: openSettingsList ? 103: 'unset' }}>
                                <img src={withPrefix("assets/img/smallGear.svg")} alt="smallGear" onClick={handleOpenSettingsList} />
                              </div>

                              {openSettingsList && (
                                <>
                                  <div className="settings-list">
                                    <div className="title">Advanced Options</div>
                                    {!carousels[currentCarouselIndex].logo && currentUser.role === "SUPERADMIN" && (
                                      <button onClick={handleAddLogo} className="confirm">
                                        <img style={{ marginRight: '10px' }} src={withPrefix("assets/img/addLogo.svg")} alt="add logo" />
                                        ADD LOGO
                                      </button>
                                    )}
                                    <button onClick={selectAllItems} className="confirm">
                                      <img style={{ marginRight: '10px' }} src={withPrefix("assets/img/selectAll.svg")} alt="select all" />
                                      SELECT ALL
                                    </button>
                                    <button onClick={unSelectAllItems} className="confirm">
                                      <img style={{ marginRight: '10px' }} src={withPrefix("assets/img/selectNone.svg")} alt="select none" />
                                      SELECT NONE
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <Swiper 
                    onSwiper={(swiper) => setSwiper(swiper)}
                    navigation={true} 
                    className="my-creations-view"
                    onSlideChange={(swiper) => setCurrentCarouselIndex(swiper.activeIndex)}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                  >
                    {carousels.map((carousel, index) => (
                      <SwiperSlide key={index}>
                        {carousel.isNewLogoCarousel ? (
                          <SelectMerchCarousel 
                            carousel={carousel} 
                            active={index===currentCarouselIndex} 
                            handleShowNextButton={() => setShowNextBtn(true)}
                          />
                        ) : (
                          <SwiperSlide key={index}>
                            <MyCreationsViewCarousel carousel={carousel} />
                          </SwiperSlide>  
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <div className="row-container">
                    <div className="container-inn">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`bottom-thumbnails alt ${isFixed ? 'fixed' : ''}`} id="bottom-thumbnails-id">
              <div className="container">
                <div className="thumbnails-inn">
                  <div className="thumbnails" id="thumbnails_id">
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="thumbnails-pagination-1"
                    >
                      {carousels.map((carousel, k) => (
                        <SwiperSlide key={k}>
                          <a 
                            href="#/" 
                            className={`box box${k + 1} 
                            ${k === currentCarouselIndex ? 'selected' : ''}`}
                          >
                            <div className="figure1">
                              <img className="image-slot" src={`${carousel.thumbnail}?version=81`} alt="placeholder-1"/>
                            </div>
                            <div className="aside">
                              <div className="number">{k + 1}</div>
                            </div>
                          </a>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                  {showNextBtn && <div className="btn-next" onClick={handleGoNextModal}>
                    <a href="#" className="btn btn-blue">
                      NEXT <em className="fal fa-angle-right"></em>
                    </a>
                  </div>}

                  {openGoNextModal && (
                    <GoNextModal 
                      handleClose={() => setOpenGoNextModal((flag) => !flag)}
                      handleClickAddStore={handleOpenNewStoreModal}
                      handleClickAddToCard={onAddToCart}
                    />
                  )}

                  {openNewStoreModal && (
                    <AddNewStoreModal 
                      handleClose={() => setOpenNewStoreModal(false)}
                      handleAddConfirm={handleConfirmNewStoreModal}
                    />
                  )}

                  {openSelectStoreModal && (
                    <SelectStoreModal 
                      handleClose={() => setOpenSelectStoreModal(false)}
                      handleClickAddStore={handleAddStoreSelectStoreModal}
                      handleClickConfirm={handleConfirmSelectStoreModal}
                      stores={UserStores}
                    />
                  )}

                  {openStoreCollectionsModal && (
                    <StoreCollectionsModal 
                      handleClose={() => setOpenStoreCollectionsModal(false)}
                      handleClickConfirm={handleConfirmStoreCollectionsModal}
                    />
                  )}

                  {openReviewModal && (
                    <ReviewCreationsModal 
                      handleClose={() => setOpenReviewModal(false)}
                      handleClickBack={handleBackReviewStoreModal}
                      handleClickConfirm={handleConfirmReviewStoreModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <ProductDetailsModal />

        <input
          ref={logoInputRef}
          type="file"
          id="image-input"
          onChange={runPipeline}
          accept=".jpeg, .png, .jpg, .webp, .heic, .svg"
          style={{ visibility: 'hidden' }}
        />

        {modalOpen && (
          <CropImageModal 
            open={modalOpen} 
            handleClose={handleCropModalClose} 
            cropperSrc={cropperSrc}
            handleConfirm={uploadCroppedImg}
          />
        )}

        <Backdrop
          sx={{ color: '#fff', zIndex: 102 }}
          open={openSettingsList}
          onClick={handleCloseSettingsList}
        >
        </Backdrop>
      </main>
      <Footer1></Footer1>
    </div>
  )
}
